/**
 * @file WorkflowLibraryNav.js
 * @created_date Thursday, December 21, 2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component represents the navigation bar within the workflow library section. It dynamically renders navigation items based on the provided list, allowing users to select different workflow libraries. The active item is visually highlighted, and clicking on a navigation item triggers the display of the corresponding workflow library content.
 *<br/>Breakdown of the code - {@tutorial WorkflowLibraryNav}
 * @module WorkflowLibraryNav
 *
 **/

import React from "react";
import WorkflowLibraryNavItem from "./WorkflowLibraryNavItem";
import workflowlibraryAPI from "../../api/workflowlibraryAPI";
import { withTranslation } from "react-i18next";
import { MiscFunctions } from 'helper-functions-package';

class WorkflowLibraryNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      activeItem: null,
      navigationItems: [],
      userObject: props.userObject,
    };
  }

  handleItemClick = (item) => {
    localStorage.setItem("activeWorkflowId", item.id.id);
    this.setState({ activeItem: item });
  };

  componentDidMount() {
    workflowlibraryAPI.getWorkflowLibraryList().then((navigationItems) => {
      this.setState({ navigationItems: navigationItems }, () => {
        this.addNavbar();
      });

      if (
        MiscFunctions.isNull(localStorage.getItem("activeWorkflowId")) &&
        !MiscFunctions.isNull(navigationItems)
      ) {
        this.handleItemClick(navigationItems[0]);
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.userObject !== this.props.userObject) {
      this.setState({ userObject: this.props.userObject }, () => {
        this.addNavbar();
      });
    }

    if (prevProps.settings !== this.props.settings && this.props.settings) {
      this.props.handleSettings(true);
      this.setState({ activeItem: null });
    }
    if (prevState.activeItem !== this.state.activeItem) {
      this.getActiveWorkflow();
    }
  }

  getActiveWorkflow = () => {
    for (let i = 0; i < this.state.navigationItems.length; i++) {
      if (
        this.state.navigationItems[i].id.id.toString() ===
        localStorage.getItem("activeWorkflowId")
      ) {
        this.setState({ activeItem: this.state.navigationItems[i] });
        this.props.selectedWorkflowLibrary(this.state.activeItem);
      }
    }
  };

  addNavbar = () => {
    this.setState((prevState) => ({
      navigationItems: prevState.navigationItems.map((item) => {
        return item;
      }),
    }));
    if (!this.state.activeItem && this.state.navigationItems.length > 0) {
      this.getActiveWorkflow();
    }
  };

  render() {
    return (
      <nav className="workflow-library-nav-block">
        {this.state.navigationItems.map((item) => (
          <WorkflowLibraryNavItem
            key={item.id.id}
            activeItem={
              !this.props.settings &&
              !MiscFunctions.isNull(this.state.activeItem) &&
              this.state.activeItem.id.id === item.id.id
                ? true
                : false
            }
            item={item}
            onClick={() => {
              console.log(item.id.id)
              this.handleItemClick(item);
              this.props.handleSettings(false);
            }}
            label={item.label}
          />
        ))}
      </nav>
    );
  }
}

export default withTranslation()(WorkflowLibraryNav);
