import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

// Wrapper-Funktion, die Router-Funktionen an Klassenkomponenten übergibt
export function withRouter(Component) {
    return function ComponentWithRouterProps(props) {
        const navigate = useNavigate();
        const location = useLocation();
        const params = useParams();

        return (
            <Component
                {...props}
                navigate={navigate}
                location={location}
                params={params}
            />
        );
    };
}
