import React, { Component } from 'react'
import InputField from "../Misc/InputField";
import ReCAPTCHA from 'react-google-recaptcha';
import { withTranslation } from 'react-i18next';
import userAPI from "../../api/userAPI";
import { getAuthAccessObject } from "../../utils/tokenStorage";
import { Spinner } from 'flowbite-react';
import ToastMessages from '../../helpers/ToastMessages';
import { Api, baseApiParams } from 'my-api-client-package';

class DeleteAccountForm extends Component {
    constructor() {
        super()
        this.state = {
            username: '',
            password: '',
            captchaValue: false,
            spinner: false,
            countdown: 3,
            showCount: false,
            inputFeilds: false,
        }
        this.recaptchaRef = React.createRef();
        this.reCaptchaKey = process.env.REACT_APP_SITE_KEY;
        this.api = new Api({
            baseUrl: process.env.REACT_APP_API_URL
        });

    }
    componentDidMount() {
        this.recaptchaRef.current?.reset();
    }

    handleChange = (fieldName, value, error) => {
        this.setState({ [fieldName]: value })
    };

    handleRecaptcha(value) {
        this.setState({ captchaValue: value })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.username !== this.state.username ||
            prevState.password !== this.state.password ||
            prevState.captchaValue !== this.state.captchaValue) {
            this.setState({ username: this.state.username })
            if (this.state.username !== '' && this.state.password !== '') this.setState({ inputFeilds: true })
            else this.setState({ inputFeilds: false })
        }

    }

    confirmDelete = async () => {
        this.setState({ spinner: true, showCount: true })
        const captchaV = await this.recaptchaRef.current.executeAsync();

        const queryParams = {
            username: this.state.username,
            userpassword: this.state.password
        }
        this.api.user.token(queryParams, baseApiParams({ captchaV })).then((response) => {
            let token = response.data.message
            if (token.includes("Forbidden")) {
                ToastMessages.erorrMessage(this.props.t('messages.incorrect_user_passowrd') + '!');
                this.setState({ spinner: false, showCount: false, inputFeilds: false })
            } else {
                this.api.user.deleteCurrentStart(baseApiParams()).then((res) => {
                    this.setState({
                        spinner: false,
                        inputFeilds: false
                    })
                    this.timer = setInterval(() => {
                        this.setState(prevState => ({
                            countdown: prevState.countdown - 1,
                        }), () => {
                            if (this.state.countdown === 0) {
                                clearInterval(this.timer);
                                this.setState({ showCount: false })
                                window.location.href = '/delete_confirm';
                            }
                        });
                    }, 1000);

                })
            }
        })
            .catch((err) => {
                ToastMessages.erorrMessage(this.props.t('messages.incorrect_user_passowrd') + '!');
                this.setState({ spinner: false, showCount: false })
            });


    }

    componentWillUnmount() {
        clearInterval(this.timer); // Clear the timer when component unmounts
    }

    render() {
        return (
            <div className="signup-block flex flex-col items-center justify-center mx-3 my-3">
                <div className="register-verify-block w-full max-w-4xl p-4 bg-white border border-gray-200 rounded-lg shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
                    <span
                        className="button_modal_close"
                        onClick={() => this.props.handleClose()}
                    ></span>
                    <h4
                        className="delete-account-heading"
                    >
                        {this.props.t('general.delete_account')}
                    </h4>
                    <div className="delete-account-form">
                        <span className="delete-account-form-text">{this.props.t('messages.please_enter_information')}.</span>
                        <div className='delete-account-form-block'>
                            <div className="delete-account-form-input-section">
                                <label className="delete-account-form-label">{this.props.t('general.email')}</label>
                                <InputField
                                    type="text"
                                    name="username"
                                    placeholder={''}
                                    value={this.state.username}
                                    onChange={this.handleChange}
                                    border={1} //false or number
                                    borderRadius={5} //false or number
                                    boxShadow={true} //true or false
                                />
                            </div>
                            <div className="delete-account-form-input-section">
                                <label className="delete-account-form-label">{this.props.t('general.password')}</label>
                                <InputField
                                    type="password"
                                    name="password"
                                    placeholder={''}
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    border={1} //false or number
                                    borderRadius={5} //false or number
                                    boxShadow={true} //true or false
                                />
                            </div>
                            <ReCAPTCHA
                                ref={this.recaptchaRef}
                                sitekey={this.reCaptchaKey}
                                hl={this.props.i18n.language}
                                size="invisible"
                                onChange={(value) => {
                                    this.handleRecaptcha(value)
                                }
                                }
                                onErrored={(error) => {
                                    console.log("rec err: ", error);
                                }}
                                onExpired={() => {
                                    console.log("Login Expired.");
                                    this.recaptchaRef.current.reset();
                                    this.setState({ captchaValue: false });
                                }}
                            />
                        </div>
                    </div>

                    <p className="delete-account-body-text">
                        {this.props.t('messages.are_you_sure_to_delete')}
                    </p>
                    <div className="flex flex-row gap-3 float-right">
                        <button
                            type="button"
                            className="delete-account-button-confirm"
                            onClick={this.confirmDelete}
                            disabled={!this.state.inputFeilds}
                        >
                            {this.state.spinner && (
                                <Spinner
                                    color="success"
                                    aria-label="Deleting"
                                    size="sm"
                                />
                            )} {" "}
                            <span>{this.props.t('general.delete_account')}</span>
                            {this.state.showCount && ` (${this.state.countdown} sec)`}
                        </button>
                        <button
                            type="button"
                            className="delete-account-button-cancel"
                            onClick={() => this.props.handleClose()}
                        >
                            {this.props.t('general.cancel')}
                        </button>
                    </div>
                </div>
            </div >
        )
    }
}

export default withTranslation()(DeleteAccountForm);