import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import Dashboard from "./components/Dasboard";
import NotFound from "./components/NotFound";
import ResetPassword from "./components/ResetPassword";
import WorkflowLibrary from "./components/WorkflowLibrary/WorkflowLibrary";
import PatientLogin from "./components/Patient/PatientLogin";
import './i18n';
import DeleteConfirmPage from "./components/DeleteConfirmPage";
import { Provider } from "react-redux";
import store from "./store/store";
import Login  from "./components/login/Login";
import { ChakraProvider } from "@chakra-ui/react";
import { Register } from "./components/register/Register";
import ProtectedRoute from "./components/login/ProtectedRoute.tsx";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ChakraProvider>
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route index element={<App />} />
        <Route path="landing_page" element={<Login />} />
        <Route path="password_reset" element={<ResetPassword />} />
        <Route path="dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="workflow_library" element={<ProtectedRoute><WorkflowLibrary /></ProtectedRoute>} />
        <Route path="patient" element={<PatientLogin />} />
        <Route path="/delete_confirm" element={<DeleteConfirmPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </Provider>
  </ChakraProvider>
  // </React.StrictMode >
);
