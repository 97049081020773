import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

class CommentAnchor extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    setClickedComment = (el) => {
        console.log(el)
    }
    render() {
        return (
            <div className="comment-anchor-block" style={{ left: this.props.elem.left, top: this.props.elem.top }} >
                <span className={`comment-anchor-circle ${this.props.elem.category}-location-color`}
                data-test="comment-anchor"
                    onClick={() => {
                        this.props.getClickedComment(this.props.elem.id)
                        this.props.closeCommentCreate()
                    }}>

                </span>
            </ div>
        )
    }
}

export default withTranslation()(CommentAnchor)