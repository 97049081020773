/**
 * @file QuickOverviewPopups.js
 * @created_date Thursday, November 30, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component manages the presentation of a modal popup with workflow information. It includes methods to control the popup's visibility, update workflow data, combine and filter resources, and toggle dropdown sections.
 *<br/>Breakdown of the code - {@tutorial QuickOverviewPopups}
 * @module QuickOverviewPopups
 *
 **/

import React from "react";
import { Modal } from "flowbite-react";
import GeneralDropDown from "../Misc/GeneralDropDown";
import { withTranslation } from "react-i18next";
import { MiscFunctions } from 'helper-functions-package';
class QuickOverviewPopups extends React.Component {
  constructor(props) {
    super();
    this.state = {
      showQuickIntroPopup: props.showIntroPopup,
      activeDropdown: "general-info",
      WorkFlowInfo: props.WorkFlowInfo,
    };
  }

  handleClose() {
    this.props.handleCloseIntroPopup(false);
  }
  componentDidMount() {
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showIntroPopup !== this.props.showIntroPopup) {
      this.setState({ showQuickIntroPopup: this.props.showIntroPopup });
    }

    if (prevProps.WorkFlowInfo !== this.props.WorkFlowInfo) {
      this.setState({ WorkFlowInfo: this.props.WorkFlowInfo });
    }
  }

  combineResources(types) {
    if (!MiscFunctions.isUndefined(types)) {
      let values = types.map((v) => v.toLowerCase());
      return this.state.WorkFlowInfo.resources.filter((item) =>
        values.includes(item.type.toLowerCase())
      );
    } else {
      return null;
    }
  }

  toggleDropdown(tag) {
    this.setState((prevState) => ({
      activeDropdown: prevState.activeDropdown === tag ? null : tag,
    }));
  }

  render() {
    const personGroups = this.combineResources(this.state.WorkFlowInfo.personGroups)
    const rooms_equipmentGroups = this.combineResources(this.state.WorkFlowInfo.rooms_equipmentGroups)
    const tool_pharmaGroups = this.combineResources(this.state.WorkFlowInfo.tool_pharmaGroups)
    return (
      <Modal
        show={this.state.showQuickIntroPopup}
        onClose={() => this.handleClose(false)}
        className="quick-intro-popup"
      >
        <Modal.Body
          className="quick_intro_popup_body"
          style={{ overflow: "inherit", flex: "none" }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="signup-block flex flex-col items-center justify-center mx-3 my-3">
            <div className="w-full">
              <span
                data-test="close-btn-for-quick-intro-popup"
                className="button_modal_close"
                onClick={() => this.handleClose(false)}
              ></span>
              <h4
                className="text-xl font-medium text-gray-900 dark:text-white"
                id="quick_intro_header_text"
              >
                {this.props.WorkFlowInfo.WorkflowName}
              </h4>
              <p id="quick_intro_sub_heading">
                {this.props.t('quick_overview_popups.workflow_id')}: {this.props.WorkFlowInfo.WorkflowId}
              </p>
              <div className="quick_intro_main_accordion">
                {this.props.WorkFlowInfo.infos.map((el, i) => {
                  if (!MiscFunctions.isNull(el.info)) {
                    return (
                      <GeneralDropDown
                        label={el.label}
                        content={el.info}
                        rightSide={false}
                        contentType="infos"
                        tag={el.tag}
                        isOpen={el.tag === this.state.activeDropdown}
                        onClick={() => this.toggleDropdown(el.tag)}
                        key={i}
                      />
                    )
                  }
                })}
                {(!MiscFunctions.isNull(personGroups)) && <GeneralDropDown
                  label={this.props.t('quick_overview_popups.roles_responsibilites')}
                  content={personGroups}
                  rightSide={true}
                  contentType="resources"
                  tag="roles-responsibilites"
                  isOpen={"roles-responsibilites" === this.state.activeDropdown}
                  onClick={() => this.toggleDropdown("roles-responsibilites")}
                />}

                {(!MiscFunctions.isNull(rooms_equipmentGroups)) && <GeneralDropDown
                  label={this.props.t('quick_overview_popups.rooms_equipment')}
                  content={rooms_equipmentGroups}
                  rightSide={true}
                  contentType="resources"
                  tag="rooms-equipment"
                  isOpen={"rooms-equipment" === this.state.activeDropdown}
                  onClick={() => this.toggleDropdown("rooms-equipment")}
                />}
                {(!MiscFunctions.isNull(tool_pharmaGroups)) && <GeneralDropDown
                  label={this.props.t('quick_overview_popups.tools_pharmaceuticals')}
                  content={tool_pharmaGroups}
                  rightSide={true}
                  contentType="resources"
                  tag="tools-pharma"
                  isOpen={"tools-pharma" === this.state.activeDropdown}
                  onClick={() => this.toggleDropdown("tools-pharma")}
                />}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withTranslation()(QuickOverviewPopups);
