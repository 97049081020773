export const WorkFlowInfoData = {
    WorkflowName: "Workflow Information",
    WorkflowId: "#",
    resources: [],
    infos: [
        {
            label: "General Information",
            tag: "general-info",
            info: [],
        },
        {
            label: "Workflow Overview",
            tag: "workflow-overview",
            info: [],
        },
        {
            label: "Questionary Introduction",
            tag: "Questionary-Introduction",
            info: [],
        },
    ],
    WorkflowOverview: {
        Phases: [],
        Tasks: [],
    },
}