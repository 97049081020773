/**
 *
 * @file ResetPasswordForm.js
 * @created_date Thurseday, Jan 19, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @description This component is developed for login into the app.
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The ResetPasswordForm component is responsible for rendering a form that allows users to reset their password. It includes various input fields, validation logic, and interaction handling related to the password reset process.
 * <br />Here's what it does: - {@tutorial ResetPasswordForm}
 * @module ResetPasswordForm
 **/

import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer } from "react-toastify";
import { Modal } from "flowbite-react";
import IndecatorUserProfile from "../assets/img/indicator_user_profile.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";
import { MiscFunctions } from 'helper-functions-package';
import ToastMessages from "../helpers/ToastMessages";
import { useTranslation } from "react-i18next";
import { Api, baseApiParams } from "my-api-client-package";

function ResetPasswordForm(props) {
  // This statement load reCaptchaKey from .env file (application rool dir).
  const reCaptchaKey = process.env.REACT_APP_SITE_KEY;

  // This hook enable/desable login button on reCaptcha verifiction.
  const [isResetVerfied, setIsResetVerified] = useState(false);

  // This hook is to set/get passowrd input.
  const [resetPassword, setResetPassword] = useState("");
  const [resetPasswordConfirm, setResetPasswordConfirm] = useState("");

  // This hook is to set/get recpacha value.
  const [captchaValue, setCaptchaValue] = useState("");

  // This hook is for enable/disable of spinner (Loading).
  const [spinner, setSpinner] = useState(false);

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const api = new Api({
    baseUrl: process.env.REACT_APP_API_URL
  });


  // Below are predefined Default/Error/Success classes which is called on it's state for inputs.
  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-mainYellowColor focus:border-mainYellowColor block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white input-style";
  const errorClass =
    "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 customize-input-style";
  const successClass =
    "bg-green-50 border border-greeColorLight text-greeColorLight dark:text-greeColorLight placeholder-greeColorLight dark:placeholder-greeColorLight text-sm rounded-lg focus:ring-greeColorLight focus:border-greeColorLight block w-full p-2.5 dark:bg-gray-700 dark:border-greeColorLight customize-input-style";

  const [isResetToken, setIsResetToken] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const getToken = searchParams.get("token");
    setIsResetToken(getToken);
  }, [searchParams]);

  /**
   * @description This function handles the form submission for password reset. It is triggered when the user submits the form. It first sets the spinner state to true to show a loading indicator. Then, it calls the resetPassword method from the authService to initiate the password reset process. If the password reset is successful (status code 200), it sets the isResetSuccess state to true to display the success popup and hides the password reset popup by calling props.setResetPopupVisible(false). If there is an error, it calls the resetError function to display an error notification.
   *
   * @function handleResetSubmit
   * @return {void}
   * */
  const [isResetSuccess, setisResetSuccess] = useState(false);
  const handleResetSubmit = async (e) => {
    setSpinner(true);
    e.preventDefault();
    const recaptchaValue = await recaptchaRef.current.executeAsync();

    const queryParams = {
      newPassword: resetPasswordConfirm
    }
    api.user.changePassword(isResetToken, queryParams, baseApiParams()).then((data) => {
      console.log(data);
      console.log(data.status);
      if (data.status === 200) {
        setisResetSuccess(true);
        props.setResetPopupVisible(false);
        setSpinner(false);
      } else {
        ToastMessages.erorrMessage(t("messages.error"));

        setSpinner(false);
      }
    })
      .catch((err) => {
        ToastMessages.erorrMessage(t("messages.error"));
        setSpinner(false);
      });
  };

  /**
   * @description This function handles the change in the reCAPTCHA value. It receives the value as a parameter and updates the captchaValue state. It also sets the isLoginVerified state to true to enable the submit button.
   *
   * @function recaptchaReset
   * @return {void}
   * */
  const recaptchaReset = (value) => {
    if (MiscFunctions.isNull(value)) {
      setIsResetVerified(true);
      setCaptchaValue("");
    } else {
      setCaptchaValue(value);
      setIsResetVerified(false);
    }
  };

  /**
   * @description This function handles the close event of the password reset success popup. It is triggered when the user clicks the close button in the popup. It sets the isResetSuccess state to false to hide the popup.
   *
   * @function handleOnCloseResetPasswordModal
   * @return {void}
   * */
  const handleOnCloseResetPasswordModal = () => {
    setisResetSuccess(false);
  };

  /**
   * @description This function redirects the user to the login page. It is called when the user clicks the "Los gehts" button in the password reset success popup. It uses the useNavigate hook from react-router-dom to navigate to the login page.
   *
   * @function redirectToLogin
   * @return {void}
   * */
  const navigate = useNavigate();
  const redirectToLogin = () => {
    navigate("/landing_page");
  };

  /**
   * @description This function is called when the user interacts with the password input field. It checks if the password is empty or does not meet the specified criteria (length < 8 or no special characters). It updates the UI by changing the input field's CSS class and sets the userPasswordIconColor and passwordInvalidLabel states accordingly.
   *
   * @function checkIfPasswordEmpty
   * @return {void}
   * */
  const [passwordInvalidLabel, setPasswordInvalidLabel] = useState("");
  const [userPasswordIconColor, setUserPasswordIconColor] = useState("");
  const checkIfPasswordEmpty = (e) => {
    if (
      !resetPassword ||
      resetPassword.length < 8 ||
      !MiscFunctions.checkIfSpecialCharacterExist(resetPassword)
    ) {
      setUserPasswordIconColor("");
      setPasswordInvalidLabel("errorLabel");
      e.target.className = errorClass;
    } else {
      setUserPasswordIconColor("normal_icon_password_confirm");
      setPasswordInvalidLabel("");
      // e.target.className = inputClasses;
    }
  };

  /**
   * @description This function is called when the user interacts with the password confirmation input field. It checks if the password confirmation field is empty and updates the UI by changing the userRepeatPasswordIconColor state.
   *
   * @function checkConfirmPasswordIfNotEmpty
   * @return {void}
   * */
  const confirmPasswordInvalidMessage = t("messages.password_must_match");
  const [userRepeatPasswordIconColor, setUserRepeatPasswordIconColor] =
    useState("");
  const [confirmPasswordInvalid, setConfirmPasswordInvalid] = useState(false);
  const checkConfirmPasswordIfNotEmpty = () => {
    if (!resetPasswordConfirm) {
      setUserRepeatPasswordIconColor("");
      setUserPasswordIconColor("");
    } else {
      setUserRepeatPasswordIconColor("normal_icon_repeat_password_confirm");
      setUserPasswordIconColor("normal_icon_password_confirm");
    }
  };

  /**
   * @description This function is called when the user interacts with the password confirmation input field. It compares the password and password confirmation values and checks if they match. If they do not match, it sets the confirmPasswordInvalid state to true and updates the CSS classes of the password input fields. If they match, it sets the confirmPasswordInvalid state to false and updates the CSS classes accordingly.
   *
   * @function checkConfirmPassword
   * @return {void}
   * */
  const checkConfirmPassword = (e) => {
    if (resetPassword !== resetPasswordConfirm && resetPasswordConfirm !== "") {
      setConfirmPasswordInvalid(true);
      document.querySelector("#reset_password").className = errorClass;
      document.querySelector("#repeat_reset_password").className = errorClass;
      setUserRepeatPasswordIconColor("");
      setUserPasswordIconColor("");
    } else if (
      resetPassword === resetPasswordConfirm &&
      resetPasswordConfirm !== ""
    ) {
      setConfirmPasswordInvalid(false);
      document.querySelector("#reset_password").className = successClass;
      document.querySelector("#repeat_reset_password").className = successClass;
      setUserRepeatPasswordIconColor("normal_icon_repeat_password_confirm");
      setUserPasswordIconColor("normal_icon_password_confirm");
    } else {
      setConfirmPasswordInvalid(false);
      document.querySelector("#reset_password").className = inputClasses;
      document.querySelector("#repeat_reset_password").className = inputClasses;
    }
  };
  const recaptchaRef = useRef(null);
  useEffect(() => {
    recaptchaRef.current?.reset();
  }, [props]);

  const [validPassword, setValidPassword] = useState(false);

  useEffect(() => {
    if (
      resetPassword &&
      resetPasswordConfirm &&
      validPassword &&
      !confirmPasswordInvalid
    ) {
      setIsResetVerified(true);
    } else {
      setIsResetVerified(false);
    }
  }, [
    resetPassword,
    resetPasswordConfirm,
    captchaValue,
    validPassword,
    confirmPasswordInvalid,
  ]);

  return (
    <>
      <ToastContainer />
      <form
        className="space-y-6 reset-form"
        onSubmit={handleResetSubmit}
        method="post"
      >
        <div className="input_group">
          <span
            className={
              userPasswordIconColor + " normal_icon_password input_icon"
            }
          ></span>
          <div className="input_group_item">
            <label
              htmlFor="password"
              className={
                passwordInvalidLabel +
                " block mb-2 text-sm font-medium text-gray-900 dark:text-white input_label"
              }
            >
              {t("login_register_page.your_password_be")}
            </label>
            <input
              type="password"
              name="reset_password"
              value={resetPassword}
              onChange={(e) => setResetPassword(e.target.value)}
              onKeyUp={checkConfirmPassword}
              onBlur={checkIfPasswordEmpty}
              onFocus={() =>
                setUserPasswordIconColor("normal_icon_password_select")
              }
              id="reset_password"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-mainYellowColor focus:border-mainYellowColor block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white input-style"
              required
            />

          </div>
        </div>
        <div className="resetFormMargin">
          {resetPassword && (
            <PasswordChecklist
              rules={[
                "minLength",
                "capital",
                "lowercase",
                "number",
                "specialChar",
              ]}
              minLength={12}
              value={resetPassword}
              valueAgain={resetPasswordConfirm}
              className="passwordCheck"
              messages={{
                minLength: t("messages.character_long", { len: 12 }),
                capital: t("messages.uppercase_letter"),
                lowercase: t("messages.lowercase_letters"),
                number: t("general.numbers"),
                specialChar: t("messages.special_characters"),
              }}
              onChange={(isValid) => {
                if (isValid === true) setValidPassword(true);
                else setValidPassword(false);
              }}
            />
          )}
        </div>
        <div className="input_group">
          <span
            className={
              userRepeatPasswordIconColor +
              " normal_icon_repeat_password input_icon"
            }
          ></span>
          <div className="input_group_item">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white input_label"
            >
              {t("login_register_page.confirm_your_password")}
            </label>
            <input
              type="password"
              name="repeat_reset_password"
              value={resetPasswordConfirm}
              onChange={(e) => setResetPasswordConfirm(e.target.value)}
              onFocus={() =>
                setUserRepeatPasswordIconColor(
                  "normal_icon_repeat_password_select"
                )
              }
              onBlur={checkConfirmPasswordIfNotEmpty}
              onKeyUp={checkConfirmPassword}
              id="repeat_reset_password"
              className={inputClasses}
              required
            />
            {confirmPasswordInvalid ? (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500 default-label-style">
                {" "}
                {confirmPasswordInvalidMessage}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>

        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={reCaptchaKey}
          hl={i18n.language}
          size="invisible"
          onChange={(value) => { setCaptchaValue(value) }}
          id="recaptcha-reset-container"
          className="container_recaptcha_reset"
          onExpired={() => {
            recaptchaRef.current.reset();
            setCaptchaValue("");
            setIsResetVerified(false);
          }}
        />

        <div className="flex flex-col items-center">
          <button
            type="submit"
            className="w-full text-white bg-hightlight-700 hover:bg-hightlight-800 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-hightlight-600 dark:hover:bg-hightlight-700 dark:focus:ring-hightlight-800 button_sign_in_up"
            onClick={(captchaValue) => handleResetSubmit(captchaValue)}
            disabled={!isResetVerfied}
          >
            {spinner ? (
              <svg
                role="status"
                className="inline mr-3 w-4 h-4 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
            ) : (
              ""
            )}{" "}
            {t("general.confirm")}
          </button>
        </div>
      </form>

      {/* Reset Password Popup. */}
      <Modal
        show={isResetSuccess}
        className="register_main_modal"
        onClick={() => setisResetSuccess(false)}
        onClose={() => {
          setisResetSuccess(false);
        }}
      >
        <Modal.Body
          className="reset_password_popup_body"
          style={{ overflow: "inherit", flex: "none" }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="signup-block flex flex-col items-center justify-center mx-3 my-3">
            <div className="register-verify-block w-full max-w-4xl p-4 bg-white border border-gray-200 rounded-lg shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
              <img
                src={IndecatorUserProfile}
                alt=""
                className="user_role_image_reset_success"
              />
              <span
                className="button_modal_close"
                onClick={handleOnCloseResetPasswordModal}
              ></span>
              <h4
                className="text-xl font-medium text-gray-900 dark:text-white text-center"
                id="label_login_header"
              >
                {t("general.congratulation")}
              </h4>
              <h5
                className="text-xl font-medium text-gray-900 dark:text-white text-center"
                id="label_confirmation_description"
              >
                <span className="boldOnly">{t("general.your")}</span> medi
                <span className="boldColored">Mesh</span>{" "}
                <span className="boldOnly">
                  {" "}
                  {t("messages.password_changed")}.
                </span>
              </h5>

              <p id="label_confirmation_state-description">
                {t("login_register_page.login_with_password")}
              </p>
              <div className="flex flex-col items-center">
                <button
                  type="submit"
                  className="w-full text-white bg-hightlight-700 hover:bg-hightlight-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-hightlight-600 dark:hover:bg-hightlight-700 dark:focus:ring-hightlight-800 button_link_to_login"
                  onClick={redirectToLogin}
                >
                  Los gehts
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ResetPasswordForm;
