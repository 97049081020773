import React, { Component } from "react";
import CommentDisplayContainer from "./CommentDisplayContainer";
import CommentCreateContainer from "./CommentCreateContainer";
import { withTranslation } from "react-i18next";
import CommentAnchor from "./CommentAnchor";
import Swal from "sweetalert2";
import { MiscFunctions } from 'helper-functions-package';

class CommentAnnotationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clickedComment: null,
      commentCreate: this.props.commentCreate,
      commentBoxAnnotation: { left: 0, top: 0 },
      switchSide: null,
      commentAnnotationContainerWidth: null,
      commentBoxPositions: [],
      propertyCommentAnnotationArray: this.props.propertyCommentAnnotationArray,
      commentUpdate: false,
      commentForUpdate: null,
      commentLoad: false,
      changesMade: false,
      commentReply: false,
      commentForReply: null,
      editCommentReply: false,
      commentEdit: false,
      checkSettingPanel: true,
    };
    this.commentAnnotationContainerRef = React.createRef();
  }

  setChangesMade = (status) => {
    this.setState({ changesMade: status })
  }

  toggleCommentContainer = () => {
    if (this.state.commentCreate === true && this.state.changesMade === true) {
      Swal.fire({
        title: "Discard Comment?",
        text: "Your changes will not be saved.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4baaac",
        cancelButtonColor: "#d33",
        confirmButtonText: "Discard",
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({
            commentCreate: false
          });
        }
      })
    }
    else {
      if (MiscFunctions.isNull(document.getElementById("comment-setting-block-id"))) {
        this.setState({
          commentCreate: false,
          commentEdit: false,
          changesMade: false,
          commentReply: false,
          editCommentReply: false
        });
      } else {
        this.setState({ checkSettingPanel: false })
      }
    }
  };

  handleSettingPanel = (status) => {
    this.setState({ checkSettingPanel: status })
  }

  placeCommentContainer(e) {
    if (this.props.addComment === false) return
    //By using e.target.closest('.comment-annotation-container'), 
    //we're ensuring that we always get the parent element with 
    //the class comment-annotation-container, regardless of the element that was actually clicked.

    //By using parent instead of e.target for calculating the left and top positions, 
    //we ensure that the positions are relative to the parent container    
    const parent = e.target.closest('.comment-annotation-container');

    if (e.target !== parent &&
      !e.target.classList.contains('comment-anchor-right') &&
      !e.target.classList.contains('comment-anchor-left') &&
      !e.target.classList.contains('comment-action-right') &&
      !e.target.classList.contains('comment-action-left')
    ) {
      return; // ignore the event
    }
    let left;
    let top;
    if (e.clientY > parent.offsetHeight || e.clientY === parent.offsetHeight) {
      top = (parent.offsetHeight - 10) / parent.offsetHeight * 100;
    } else {
      top = e.clientY / parent.offsetHeight * 100;
    }
    if (e.clientX > parent.offsetWidth || e.clientX === parent.offsetWidth) {
      left = (parent.offsetWidth - 10) / parent.offsetWidth * 100;
    } else {
      left = e.clientX / parent.offsetWidth * 100;
    }

    this.setState({ commentBoxPositions: [left / 100, top / 100] });

    this.closeDislayComment()
    this.props.getClickedComment(null)

    this.setState({ commentBoxAnnotation: { left: `${left}%`, top: `${top}%` }, commentCreate: true, clickedComment: null, commentForUpdate: null, commentUpdate: false })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.clickedComment !== this.props.clickedComment) {
      this.setState({ clickedComment: this.props.clickedComment })
    }
    if (prevState.commentBoxPositions !== this.state.commentBoxPositions) {
      this.setState({ commentBoxPositions: this.state.commentBoxPositions })
    }

    if (prevState.propertyCommentAnnotationArray !== this.props.propertyCommentAnnotationArray) {

      this.setState({ propertyCommentAnnotationArray: this.props.propertyCommentAnnotationArray })
      const commetnAnn = this.props.propertyCommentAnnotationArray;

      if (commetnAnn && commetnAnn.length > 0) {
        commetnAnn.map((el, i) => {
          if (el.mainComment?.commentSettings?.autoplay !== undefined && el.mainComment && el.mainComment.commentSettings.autoplay === true) {
            this.setState({ selectedComment: el.mainComment })
            this.props.setClickedComment(el.mainComment)
          }
        })

      }
      this.setCommentLoad(false)
    }
    if (this.props.commentCreate !== this.state.commentCreate) {
      if (this.props.infoCard === true) {
        this.toggleCommentContainer();
        this.props.setInfoCard(false)
      }
      if (this.props.clickPlayIconInSiderbar === true) {
        this.setState({ commentCreate: this.props.commentCreate });
        this.props.setClickPlayIconInSiderbar(false);
      }
    }
  }

  setCommentLoad = (status) => {
    this.state.commentForUpdate && this.setState({ commentLoad: status })
  }
  componentDidMount() {
    this.updateSwitchSide();
    window.addEventListener('resize', this.updateSwitchSide);
  }

  // Lifecycle method called when the component is unmounted
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSwitchSide);
  }

  // Method to update the switchSide state based on container width
  updateSwitchSide = () => {
    if (this.commentAnnotationContainerRef.current) {
      const width = this.commentAnnotationContainerRef.current.clientWidth;
      this.setState({
        switchSide: parseFloat(width) < 10175 ? 'right' : 'left',
        commentAnnotationContainerWidth: parseFloat(width)
      });
    }
  };

  closeDislayComment = () => {
    this.props.getClickedComment(null)
  }

  closeCommentCreate = (status) => {
    if (status === 'save') this.setState({ commentCreate: false })
    else this.toggleCommentContainer()
  }

  closeCommentReply = (status) => {
    if (status === 'save') this.setState({ commentReply: false })
    else this.toggleCommentContainer()
  }
  closeCommentReplyEdit = (status) => {
    if (status === 'save') this.setState({ editCommentReply: false, commentEdit: false })
    else this.toggleCommentContainer()
  }

  handlecommentUpdate = (commentObj, switchSide, left, top) => {
    this.setState({
      commentUpdate: true,
      commentCreate: true,
      commentForUpdate: commentObj,
      commentBoxAnnotation: { left: `${left}`, top: `${top}` },
      switchSide: switchSide,
      commentBoxPositions: [parseFloat(left) / 100, parseFloat(top) / 100],
      commentReply: false,
      editCommentReply: false
    })
    this.props.getClickedComment(null)
  }

  handleCommentReply = (commentObj, switchSide, left, top) => {
    this.setState({
      commentReply: true,
      commentForReply: commentObj,
      commentBoxAnnotation: { left: `${left}`, top: `${top}` },
      switchSide: switchSide,
      commentBoxPositions: [parseFloat(left) / 100, parseFloat(top) / 100]
    });
    let element;
    if (!MiscFunctions.isUndefined(document.getElementsByClassName("comment-reply-main-block")) && !MiscFunctions.isNull(document.getElementsByClassName("comment-reply-main-block"))) {
      element = document.getElementsByClassName("comment-reply-main-block");
      element[0].scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  handleEditCommentReply = (commentObj, switchSide, left, top, reply) => {
    this.setState({
      editCommentReply: true,
      commentEdit: true,
      commentForReply: commentObj,
      commentBoxAnnotation: { left: `${left}`, top: `${top}` },
      switchSide: switchSide,
      commentBoxPositions: [parseFloat(left) / 100, parseFloat(top) / 100]
    })
  }

  render() {
    const { propertyCommentAnnotationArray, getClickedComment, companyUsers, user, videoID, player, setAddComment, filterCategories, passVideoCaption, deleteComment, setDeleteComment } = this.props;
    const { clickedComment, switchSide, commentAnnotationContainerWidth, commentBoxAnnotation, commentBoxPositions } = this.state;
    return (
      <div
        data-test="comment-annotation-container-click-for-comment-create"
        className="comment-annotation-container"
        ref={this.commentAnnotationContainerRef}
        onClick={(e) => {
          const parent = e.target.closest('.comment-annotation-container');
          if (e.target !== parent &&
            !e.target.classList.contains('comment-anchor-right') &&
            !e.target.classList.contains('comment-anchor-left') &&
            !e.target.classList.contains('comment-action-right') &&
            !e.target.classList.contains('comment-action-left')) {
              if (this.props.clickPlayIconInSiderbar === true) {
                this.props.setClickPlayIconInSiderbar(false);
              }
            return true
          }
          else if (this.props.clickedComment !== undefined && this.props.clickedComment !== null) {
            if (this.props.clickPlayIconInSiderbar === true) {
              this.props.setClickPlayIconInSiderbar(false);
            }
            this.props.getClickedComment(null);
          }
          else {
            this.toggleCommentContainer();
            !this.state.commentCreate && this.placeCommentContainer(e)
          }
        }}
      >
        {
          propertyCommentAnnotationArray.map((elem, i) => {
            // if (clickedComment !== null && clickedComment !== undefined) {
            //   return elem.id !== clickedComment.id && <CommentAnchor key={i} elem={elem} getClickedComment={getClickedComment} />
            // }
            return <CommentAnchor
              key={i}
              elem={elem}
              getClickedComment={getClickedComment}
              closeCommentCreate={this.closeCommentCreate} />
          })
        }
        {
          (clickedComment !== null && clickedComment !== undefined) && propertyCommentAnnotationArray.map((elem, i) => {
            return elem.id === clickedComment.id && (
              <CommentDisplayContainer
                key={i}
                commentBoxAnnotation={elem}
                companyUsers={companyUsers}
                passVideoCaption={passVideoCaption}
                getClickedComment={getClickedComment}
                clickedComment={clickedComment}
                propertyCommentAnnotationArray={propertyCommentAnnotationArray}
                deleteComment={deleteComment}
                setDeleteComment={setDeleteComment}
                deteleCommentId={this.props.deleteCommentId}
                setDeleteCommentId={this.props.setDeleteCommentId}
                switchSide={switchSide}
                commentAnnotationContainerWidth={commentAnnotationContainerWidth}
                closeDislayComment={this.closeDislayComment}
                user={user}
                setUpdateMediaFile={this.props.setUpdateMediaFile}
                updateMediaFile={this.props.updateMediaFile}
                handlecommentUpdate={this.handlecommentUpdate}
                handleCommentReply={this.handleCommentReply}
                handleEditCommentReply={this.handleEditCommentReply}
                commentLoad={this.state.commentLoad}
                commentReply={this.state.commentReply}
                commentForReply={this.state.commentForReply}
                closeCommentReply={this.closeCommentReply}
                closeCommentReplyEdit={this.closeCommentReplyEdit}
                setChangesMade={this.setChangesMade}
                setCommentLoad={this.setCommentLoad}
                setToastMessage={this.props.setToastMessage}
                setAddComment={setAddComment}
                commentUpdated={this.props.commentUpdated}
                setCommentUpdated={this.props.setCommentUpdated}
                editCommentReply={this.state.editCommentReply}
                commentEdit={this.state.commentEdit}
              />
            );
          })
        }
        {this.state.commentCreate &&
          <CommentCreateContainer
            setToastMessage={this.props.setToastMessage}
            commentAnnotationContainerWidth={commentAnnotationContainerWidth}
            commentBoxAnnotation={commentBoxAnnotation}
            user={user}
            switchSide={switchSide}
            clickedComment={clickedComment}
            companyUsers={companyUsers}
            videoID={videoID}
            commentBoxPositions={commentBoxPositions}
            player={player}
            setAddComment={setAddComment}
            filterCategories={filterCategories}
            closeCommentCreate={this.closeCommentCreate}
            closeCommentReply={this.closeCommentReply}
            commentUpdate={this.state.commentUpdate}
            getClickedComment={getClickedComment}
            commentForUpdate={this.state.commentForUpdate}
            setCommentLoad={this.setCommentLoad}
            setUpdateMediaFile={this.props.setUpdateMediaFile}
            updateMediaFile={this.props.updateMediaFile}
            setChangesMade={this.setChangesMade}
            commentReply={this.state.commentReply}
            commentForReply={this.state.commentForReply}
            checkSettingPanel={this.state.checkSettingPanel}
            handleSettingPanel={this.handleSettingPanel}
          />}

      </div>
    );
  }
}

export default withTranslation()(CommentAnnotationContainer);
