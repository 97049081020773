/**
 * @file QuickOverviewPopupsContainer.js
 * @created_date Thursday, November 30, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component serves as a container for managing the data flow and interactions related to displaying the quick overview popups. It fetches video resources, processes data, updates the state, and conditionally renders the QuickOverviewPopups component based on the visibility condition.
 *<br/>Breakdown of the code - {@tutorial QuickOverviewPopupsContainer}
 * @module QuickOverviewPopupsContainer
 *
 **/

import React from "react";
import QuickOverviewPopups from "./QuickOverviewPopups";
import { withTranslation } from "react-i18next";
import store from "../../store/store";
import VideoService from "../../services/VideoService";
import { WorkFlowInfoData } from "./QuickOverviewWorkflowObject";

class QuickOverviewPopupsContainer extends React.Component {
  constructor(props) {
    super();

    this.state = {
      VideoObject: new VideoService(store.getState().video),
      workflow: props.workflow,
      newWorkFlowInfo: structuredClone(WorkFlowInfoData),
      video: null,
      ressources: props.ressources,
      groupPersons: null,
      dataReady: false,
      showIntroPopup: props.showIntroPopup,
      context: null
    };
  }

  handleStorage = () => {
    this.setState({
      introPopupShown: localStorage.getItem("hasSeenIntroductionPopup"),
    });
  };

  uniqueLabel(objects) {
    if (objects) {
      const uniqueObjects = {};
      objects.forEach((obj) => {
        if (!uniqueObjects[obj.label]) {
          uniqueObjects[obj.label] = obj;
        }
      });
      return Object.values(uniqueObjects);
    }
    return [];
  }

  resourceDataMap(resource, type) {
    if (resource) {
      let Data = {
        id: resource.getID,
        type: type,
        label: resource.getLabel,
        description: resource.getDescription,
        picture: resource.getPicture,
      };
      return Data;
    }
    return [];
  }

  infoDataMap(newWorkFlowInfo, video, i, label, string) {
    newWorkFlowInfo.infos[0].info.push({
      label: label,
      description: video.contexts[i].getValueByString(string),
    });
    return newWorkFlowInfo;
  }

  componentDidMount() {
    const newWorkFlowInfo = this.props.getWorkflowObject(structuredClone(WorkFlowInfoData), this.props.t, this.resourceDataMap, this.uniqueLabel)
    this.setState({ newWorkFlowInfo: newWorkFlowInfo })
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.introPopupShown !==
      localStorage.getItem("hasSeenIntroductionPopup")
    ) {
      this.setState({
        introPopupShown: localStorage.getItem("hasSeenIntroductionPopup"),
      });
    }


    if (prevProps.workflow !== this.state.workflow) {
      this.setState({ workflow: this.props.workflow });
    }
    if (prevProps.showIntroPopup !== this.props.showIntroPopup) {
      this.setState({ showIntroPopup: this.props.showIntroPopup });
    }

    if (prevProps.ressources !== this.props.ressources) {
      const newWorkFlowInfo = this.props.getWorkflowObject(structuredClone(WorkFlowInfoData), this.props.t, this.resourceDataMap, this.uniqueLabel)
      this.setState({ newWorkFlowInfo: newWorkFlowInfo })
    }
  }
  render() {
    return (
      this.state.showIntroPopup && this.state.newWorkFlowInfo && (
        <QuickOverviewPopups
          WorkFlowInfo={this.state.newWorkFlowInfo}
          showIntroPopup={this.state.showIntroPopup}
          handleCloseIntroPopup={this.props.handleCloseIntroPopup}
        />
      )
    );
  }
}

export default withTranslation()(QuickOverviewPopupsContainer);
