import React, { Component } from 'react'
import InputField from "../Misc/InputField";
import ReCAPTCHA from 'react-google-recaptcha';
import { withTranslation } from 'react-i18next';
import { Spinner } from 'flowbite-react';
import ToastMessages from '../../helpers/ToastMessages';
import { MiscFunctions } from 'helper-functions-package';
import { Api, baseApiParams } from 'my-api-client-package';

class PasswordResetForm extends Component {
    constructor() {
        super()
        this.state = {
            username: '',
            captchaValue: false,
            spinner: false,
            countdown: 3,
            showCount: false,
            inputFeilds: false,
        }
        this.recaptchaRef = React.createRef();
        this.reCaptchaKey = process.env.REACT_APP_SITE_KEY;
        this.api = new Api({
            baseUrl: process.env.REACT_APP_API_URL
        });

    }
    componentDidMount() {
        this.recaptchaRef.current?.reset();
    }

    handleChange = (fieldName, value, error) => {
        this.setState({ [fieldName]: value })
    };

    handleRecaptcha(value) {
        this.setState({ captchaValue: value })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.username !== this.state.username ||
            prevState.captchaValue !== this.state.captchaValue) {
            this.setState({ username: this.state.username })
            if (this.state.username !== '' && this.state.captchaValue !== false) this.setState({ inputFeilds: true })
            else this.setState({ inputFeilds: false })
        }

    }

    handleResetSubmit = async (e) => {
        this.setState({ spinner: true });
        e.preventDefault();
        const email = MiscFunctions.emailValidation(this.state.username);
        if (!email) {
            ToastMessages.erorrMessage(this.props.t("messages.email_is_invalid"));

            this.setState({ spinner: false });
        } else {
            this.api.user.resetPassword(this.state.username, baseApiParams()).then((data) => {
                if (data) {
                    this.setState({ spinner: false, username: '' });
                    ToastMessages.successMessage(this.props.t("login_register_page.an_email_sent"));
                }
            });
            this.setState({ spinner: false });
        }
    };

    render() {
        return (
            <div className="signup-block flex flex-col items-center justify-center mx-3 my-3">
                <div className="register-verify-block w-full max-w-4xl p-4 bg-white border border-gray-200 rounded-lg shadow-md sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
                    <span
                        className="button_modal_close"
                        onClick={() => this.props.handleClose()}
                    ></span>
                    <h4
                        className="delete-account-heading"
                    >
                        {this.props.t('login_register_page.reset_password')}
                    </h4>
                    <div className="delete-account-form">
                        <span className="delete-account-form-text">{this.props.t('messages.please_enter_information')}.</span>
                        <div className='delete-account-form-block'>
                            <div className="reset-password-form-input-section ">
                                <label className="reset-account-form-label">{this.props.t('messages.enter_email_to_continue')}</label>
                                <InputField
                                    type="text"
                                    name="username"
                                    placeholder={''}
                                    value={this.state.username}
                                    onChange={this.handleChange}
                                    border={1} //false or number
                                    borderRadius={5} //false or number
                                    boxShadow={true} //true or false
                                />
                            </div>
                            <ReCAPTCHA
                                ref={this.recaptchaRef}
                                sitekey={this.reCaptchaKey}
                                hl={this.props.i18n.language}
                                onChange={(value) => {
                                    this.handleRecaptcha(value)
                                }
                                }
                                onErrored={(error) => {
                                    console.log("rec err: ", error);
                                }}
                                onExpired={() => {
                                    console.log("Login Expired.");
                                    this.recaptchaRef.current.reset();
                                    this.setState({ captchaValue: false });
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row gap-3 float-right">
                        <button
                            type="button"
                            className="reset-password-button"
                            onClick={this.handleResetSubmit}
                            disabled={!this.state.inputFeilds}
                        >
                            {this.state.spinner && (
                                <Spinner
                                    color="success"
                                    aria-label="Deleting"
                                    size="sm"
                                />
                            )} {" "}
                            <span>{this.props.t('login_register_page.reset_password')}</span>
                            {this.state.showCount && ` (${this.state.countdown} sec)`}
                        </button>
                        <button
                            type="button"
                            className="delete-account-button-cancel"
                            onClick={() => this.props.handleClose()}
                        >
                            {this.props.t('general.cancel')}
                        </button>
                    </div>
                </div>
            </div >
        )
    }
}

export default withTranslation()(PasswordResetForm);