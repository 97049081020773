import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { getAuthAccessObject, setAuthAccessObject } from '../../utils/tokenStorage';


interface ProtectedRouteProps {
    children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const queryParams = new URLSearchParams(window.location.search);
    const redirectedToken = queryParams.get('_token');
    if (redirectedToken) {
        setAuthAccessObject(
            JSON.stringify({
                loginStatus: true,
                token: redirectedToken,
                recaptcha: "",
            })
        );
    }

    if (!getAuthAccessObject()) {
        return <Navigate to="/login" />;
    }

    // Otherwise, render the child component (i.e., the protected page)
    return <>{children}</>;
};

export default ProtectedRoute;
