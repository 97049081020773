/**
 *
 * @file SwimlaneInfoBar.js
 * @created_date Wednesday, August 01, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component is designed to display a label within the designated area in the workflow user interface. The label content is derived from the "videoLabel" prop that is passed to this component from its parent.
 *<br/>Key functionalities - {@tutorial SwimlaneInfoBar}
 * @module SwimlaneInfoBar
 *
 **/
import React from "react";
import { ElementLayoutHelpers } from "helper-functions-package"
import PersonGroupInfo from "./SwimlaneInforBarPersonGroup";
import { MiscFunctions } from 'helper-functions-package';
import store from "../../store/store";
class PlayerSwimlaneInfoBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverflowing: false,
      finalGroup: this.props.finalGroup,
      workflowInfobarHover: false,
      video: store.getState().video
    };

    this.workflowName = React.createRef();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.finalGroup !== this.props.finalGroup) {
      this.setState({ finalGroup: this.props.finalGroup });
    }
  }

  componentDidMount() {
    this.setState({
      isOverflowing: ElementLayoutHelpers.checkOverflow(this.workflowName),
    });
  }

  render() {
    return (
      <div className="swimlane-label-box">
        <span
          className={`workflow-side-infobar workflow_side_infobar_transition ${this.props.showIntroPopup === true
            ? " workflow-side-infobar-selected"
            : ""
            }`}
          onClick={() => (!MiscFunctions.isNull(this.state.video.contexts)) 
            ? this.props.handleOpenIntroPopup() 
            : undefined}
          onMouseOver={() => {
            setTimeout(() => {
              this.setState({ workflowInfobarHover: true })
            }, 200)
          }}
          onMouseOut={() => {
            setTimeout(() => {
              this.setState({ workflowInfobarHover: false })
            }, 200)
          }}
        >
          <span
            className={`infobar_transition workflow-arrow-side-infobar${this.state.workflowInfobarHover
              ? " workflow-arrow-side-infobar-hover"
              : ""
              } ${this.props.showIntroPopup === true
                ? " workflow-arrow-side-infobar-selected"
                : ""
              }`}
          >
            {this.state.workflowInfobarHover && (
              <span className="workflow-arrow-icon-side-infobar"></span>
            )}
            {this.props.showIntroPopup && (
              <span className="workflow-arrow-icon-side-infobar-selected"></span>
            )}
          </span>
          <span className="workflow-details-side-infobar">
            <span
              className="workflow-name-side-infobar"
              ref={this.workflowName}
            >
              {this.props.videoObject.videoLabel}
            </span>
            {this.state.isOverflowing && (
              <span className="workflow-name-tooltip-side-infobar">
                {this.props.videoObject.videoLabel} <br />
                {this.props.videoObject.videoID}
              </span>
            )}
            <span className="workflow-id-side-infobar">{`id: ${this.props.videoObject.videoID}`}</span>
          </span>
        </span>

        <PersonGroupInfo finalGroup={this.state.finalGroup} />
      </div>
    );
  }
}
export default PlayerSwimlaneInfoBar;
