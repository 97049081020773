// Redux Slice (workflowsSlice.js)
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    workflows: [], // Initialer Zustand
    activeLibrary: null
};

const workflowlibrarySlice = createSlice({
    name: "workflows",
    initialState,
    reducers: {
        setWorkflowlibrary(state, action) {
            state.workflows = action.payload;
        },
        clearWorkflows(state) {
            state.workflows = []; // Workflows leeren
        },
        setActivelibrary(state, action) {
            state.activeLibrary = action.payload;
        }
    },
});

export const { setWorkflowlibrary, clearWorkflows, setActivelibrary} = workflowlibrarySlice.actions;
export default workflowlibrarySlice.reducer;
