/**
 *
 * @file mentionsInputStyle_Annotation.js
 * @created_date Thursday, December 15, 2022
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The component define an object that contains CSS styles and properties. It appears to define a set of styles related to a control component, including styles for different scenarios such as multi-line and single-line input, as well as suggestions.
 * <br/>Breakdown of the code - {@tutorial MentionInputStyle_Annotation}
 * @module MentionInputStyle_Annotation
 **/

export default {
  control: {
    marginTop: "10px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    zIndex: 1,
    fontFamily: "Poppins Regular, sans-serif",
    position: "relative",
    width: "100% !important",
    borderRadius: "5px",
    left: '-5px'
  },

  "&multiLine": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    control: {
      fontFamily: "Poppins Regular, sans-serif",
      minHeight: 50,
    },
    highlighter: {
      overflow: "hidden",
      lineHeight: "inherit",
      padding: 0,
      paddingRight: '5px',
      paddingTop: '1px',
      border: '1px solid transparent',
      outline: "none !important",
      height: 50,
      backgroundColor: "#F2F2F2",
      borderRadius: "5px",
    },
    input: {
      overflowY: "auto",
      lineHeight: "inherit",
      padding: 5,
      border: "none",
      height: 50,
      borderRadius: "5px",
      outline: "1px solid #E2E8F0",

    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "none !important",
      outline: "none !important",
      color: "#4BAAAC",
    },
    input: {
      padding: 1,
      color: "#4BAAAC"
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: "12px",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        color: "#4BAAAC",
        backgroundColor: '#f2f2f2'
      },

    },
  },
};
