import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Avatar from "../Misc/Avatar";
import AudioRecorder from "../Misc/AudioRecorder";
import AudioPlayer from "../Misc/AudioPlayer";
import MiscFunctionsLocal from "../../helpers/MiscFunctions";
import { MiscFunctions } from 'helper-functions-package';
import mentionsInputStyle_Annotation from "../mentionsInputStyle_Annotation";
import { Mention, MentionsInput } from "react-mentions";
import Comment from "../../services/CommentServices";
import commentAPI from "../../api/commentAPI";
import Swal from "sweetalert2";
import mediaAPI from "../../api/mediaAPI";

class CommentDetials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentView: this.props.commentDisplay ? true : false,
      commentEdit: this.props.commentEdit ? true : false,
      commentReply: this.props.commentReply ? true : false,
      checkCommentIsReply: this.props.checkCommentIsReply ? true : false,
      selectedCategory: this.props.commentEdit
        ? this.props.commentUpdate
          ? this.props.commentForUpdate.getCategories[0]
          : !MiscFunctions.isUndefined(this.props.filterCategories) && this.props.filterCategories.find(i => i.label === "Comment")
        : !MiscFunctions.isUndefined(this.props.filterCategories) && this.props.filterCategories.find(i => i.label === "Comment"),
      showDropDown: false,
      userMentions: this.props.companyUsers,
      commentTitle: this.props.commentUpdate
        ? this.props.commentForUpdate.getHeadline
        : "",
      commentContent: this.props.commentUpdate
        ? this.props.commentForUpdate.getContent
        : "",
      filterCategories: this.props.filterCategories,
      audioJson:
        (!MiscFunctions.isNull(this.props.commentForUpdate) &&
          !MiscFunctions.isUndefined(this.props.commentForUpdate) &&
          !MiscFunctions.isNull(this.props.commentForUpdate.recordedAudio) &&
          !MiscFunctions.isUndefined(this.props.commentForUpdate.recordedAudio))
          ? this.props.commentForUpdate.recordedAudio.id
          : (!MiscFunctions.isNull(this.props.commentForReply) &&
            !MiscFunctions.isUndefined(this.props.commentForReply) &&
            !MiscFunctions.isNull(this.props.commentForReply.recordedAudio) &&
            !MiscFunctions.isUndefined(this.props.commentForReply.recordedAudio))
            ? this.props.commentForReply.recordedAudio.id
            : null,
      clickedComment: this.props.clickedComment,
      showMore: false,
      contentFocus: false,
      deleteRecordedAudio: false,
      switchSide: this.props.switchSide,
      changeContent: false,
    };
    this.props.commentUpdate &&
      this.props.handleSelectedCategory(
        this.props.commentForUpdate.category[0]
      );
  }

  handleSelectCategory = (category) => {
    this.props.handleSelectedCategory(category);
    this.setState((prevState) => ({
      selectedCategory:
        prevState.selectedCategory === category ? null : category,
      showDropDown: false,
    }));
    this.props.setChangesMade(true);
  };

  handleToggleDropDown = () => {
    this.setState((prevState) => ({
      showDropDown: !prevState.showDropDown,
    }));
  };

  handleCommentTitle = (event) => {
    const value = event.target.value;
    this.setState({ commentTitle: value });
    this.props.setChangesMade(true);
    if (value === "" && !this.props.commentUpdate)
      this.props.setChangesMade(false);
  };

  handleCommentContent = (event) => {
    const value = event.target.value;
    this.setState({ commentContent: value, changeContent: true });
    this.props.setChangesMade(true);
    if (value === "" && !this.props.commentUpdate)
      this.props.setChangesMade(false);
  };

  handleRecordedAudio = (event) => {
    const value = event;
    this.setState({ audioJson: event });
    this.props.setChangesMade(true);
    if (value === null && !this.props.commentUpdate)
      this.props.setChangesMade(false);
  };

  handleDeleteRecordedAudio = async (mediaId, commentId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4baaac",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        mediaAPI.deleteMediaById(mediaId);
        this.setState({ deleteRecordedAudio: true });
      }
    });
  };

  handlePostComment = async () => {
    if (!this.state.commentReply && !this.props.editCommentReply) {
      if (
        this.state.commentContent !== "" &&
        this.state.commentTitle !== "" &&
        this.state.selectedCategory.id !== null &&
        !this.state.commentReply
      ) {
        const commentSettings = {
          autoplay: this.props.autoplay,
          stopWhenReached: this.props.stopWhenReached,
          allowReplies: this.props.allowReplies,
        };

        let comment = new Comment({
          content: MiscFunctionsLocal.checkCommentText(this.state.commentContent),
          headline: MiscFunctionsLocal.checkCommentText(this.state.commentTitle),
          medias: this.props.commentMedias,
          commentSettings: commentSettings,
          recordedAudio: this.state.audioJson,
        });

        comment.setVideoID = this.props.videoID;
        comment.setUserID = this.props.user.UserID;
        comment.setPosition = this.props.commentBoxPositions;
        comment.categories = [
          { id: this.state.selectedCategory.id, tag: "category" },
        ];
        if (this.props.commentVisibilityStatusPublic) {
          comment.setVisibility = "public";
        } else {
          comment.setVisibility = "private";
        }
        let resComm;
        if (this.props.commentUpdate) {
          comment.setCommentID = this.props.commentForUpdate.getID;
          resComm = await commentAPI.putComment(comment.json);
        } else {
          console.log({
            comment: comment.json,
            timestamp: this.props.player.current.currentTime(),
          });

          resComm = await commentAPI.postComment({
            comment: comment.json,
            timestamp: this.props.player.current.currentTime(),
          });
        }

        if (!MiscFunctions.isUndefined(resComm)) {
          if (this.props.commentUpdate) {
            this.props.closeCommentCreate("save");
            this.props.setAddComment(comment);
            this.props.getClickedComment(comment.getID.id);
            Swal.fire({
              title: "Done",
              text: "The comment has been updated!",
              icon: "success",
              confirmButtonColor: "#4baaac",
              confirmButtonText: "OK",
            });
          }

          if (resComm.status === 201 || resComm.status === 200) {
            console.log("Comment Added/Updated.");
            this.props.closeCommentCreate("save");
            this.props.setAddComment(comment);
            Swal.fire({
              title: "Done",
              text: "The comment has been added!",
              icon: "success",
              confirmButtonColor: "#4baaac",
              confirmButtonText: "OK",
            });
          }
        }
      } else {
        this.props.setToastMessage(
          "Fill the required fields (Title, Content and Category)."
        );
        this.props.setSaveError(true);
      }
    } else {
      if (this.state.commentContent !== "" && (this.state.commentReply || this.props.editCommentReply)) {
        let resComm;
        let comment = new Comment({
          content: MiscFunctionsLocal.checkCommentText(this.state.commentContent),
          headline: MiscFunctionsLocal.checkCommentText(
            this.state.clickedComment.headline
          ),
          medias: this.props.commentMedias,
          commentSettings: this.state.clickedComment.commentSettings,
          recordedAudio: this.state.audioJson,
          parent: this.state.clickedComment.parent,
          previous: this.state.clickedComment.parent,
          visibility: "private",
        });
        comment.setVideoID = this.state.clickedComment.video.id;
        comment.setUserID = this.props.user.UserID;
        comment.setPosition = this.state.clickedComment.position;
        comment.categories = [
          { id: this.state.clickedComment.category[0].id, tag: "category" },
        ];
        if (this.props.editCommentReply) {
          if (this.state.changeContent && this.props.commentForReply.getContent !== this.state.commentContent) {
            comment.setCommentID = this.props.commentForReply.getID;
            resComm = await commentAPI.putComment(comment.json);
          }
          if (this.state.changeContent === false) {
            comment.setCommentID = this.props.commentForReply.getID;
            resComm = await commentAPI.putComment(comment.json);
          }
        } else {
          resComm = await commentAPI.postComment(
            {
              comment: comment.json,
              timestamp: MiscFunctions.DateString2Seconds(this.state.clickedComment.timestamp.timestamp)
            }
          );
        }
        if (!MiscFunctions.isUndefined(resComm)) {
          if (this.props.editCommentReply) {
            this.props.closeCommentReplyEdit("save");
            this.props.setAddComment(comment);
            this.props.getClickedComment(comment.parent.id);
            Swal.fire({
              title: "Done",
              text: "The comment has been updated!",
              icon: "success",
              confirmButtonColor: "#4baaac",
              confirmButtonText: "OK",
            });
            this.setState({ changeContent: false });
          }
          if (resComm.status === 201 || resComm.status === 200) {
            console.log("Comment Added/Updated.");
            if (this.props.editCommentReply) {
              this.props.closeCommentReplyEdit("save");
            } else {
              this.props.closeCommentReply("save");
            }
            this.props.setAddComment(comment);
            Swal.fire({
              title: "Done",
              text: "The comment has been added!",
              icon: "success",
              confirmButtonColor: "#4baaac",
              confirmButtonText: "OK",
            });
          }
        }
      } else {
        this.props.setToastMessage("Fill the required fields (Content).");
        this.props.setSaveError(true);
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.saveButtonClick !== this.props.saveButtonClick) {
      this.handlePostComment();
      if (!this.state.commentReply) {
        this.props.setUpdateMediaFile(!this.props.updateMediaFile);
      }
    }
    if (prevProps.commentForUpdate !== this.props.commentForUpdate) {
      this.setState({
        commentTitle: this.props.commentUpdate
          ? this.props.commentForUpdate.getHeadline
          : "",
        commentContent: this.props.commentUpdate
          ? this.props.commentForUpdate.getContent
          : "",
        selectedCategory: this.props.commentEdit
          ? this.props.commentUpdate
            ? this.props.commentForUpdate.getCategories[0]
            : !MiscFunctions.isUndefined(this.props.filterCategories) && this.props.filterCategories.find(i => i.label === "Comment")
          : !MiscFunctions.isUndefined(this.props.filterCategories) && this.props.filterCategories.find(i => i.label === "Comment"),
      });
    }

    if (prevProps.commentForReply !== this.props.commentForReply) {
      this.setState({
        commentContent: this.props.editCommentReply
          ? this.props.commentForReply.getContent
          : "",
        audioJson: this.props.editCommentReply && !MiscFunctions.isNull(this.props.commentForReply) && !MiscFunctions.isNull(this.props.commentForReply.recordedAudio)
          ? this.props.commentForReply.recordedAudio.id
          : "",
      });
    }

    if (prevProps.clickedComment !== this.props.clickedComment) {
      this.setState({ clickedComment: this.props.clickedComment });
    }
    if (prevProps.switchSide !== this.props.switchSide) {
      this.setState({ switchSide: this.props.switchSide });
    }
  }

  render() {
    const {
      selectedCategory,
      showDropDown,
      filterCategories,
      clickedComment,
      switchSide,
    } = this.state;
    let newFilterCategories = [];
    if (this.state.commentEdit) {
      // This is a temporary and quick implementation for removing the old categories. It should be removed once the old categories are deleted from the database.
      const targetCategoryTypes = ["ask_ai", "comment", "question", "notice"];
      newFilterCategories = filterCategories.filter((item) =>
        targetCategoryTypes.includes(item.type)
      );
    }
    return (
      <>
        <div
          className="comment-details-block"
          style={{
            borderRadius: `${!MiscFunctions.isNull(switchSide) &&
              !MiscFunctions.isUndefined(switchSide) &&
              switchSide === "left"
              ? "0 5px 0 0"
              : "5px 0 0 0"
              }`,
          }}
        >
          {/* Comment Heading */}
          <div className="comment-details">
            <div className="comment-details-header">
              <div className="comment-input-category">
                {/* Comment Edit */}
                {this.state.commentEdit && (
                  <input
                    data-test="input-comment-details"
                    type="text"
                    className="comment-details-title-input"
                    placeholder="Add a title"
                    value={this.state.commentTitle}
                    onChange={this.handleCommentTitle}
                    autoFocus
                  />
                )}

                {/* Comment View */}
                {this.state.commentView && !this.state.checkCommentIsReply && !this.props.editCommentReply && (
                  <span className="comment-details-title">
                    {clickedComment.getHeadline}
                  </span>
                )}
                {this.state.checkCommentIsReply && (
                  <span className="comment-details-title-input" />
                )}
                {/* Comment Category */}
                {(!this.props.commentReply || !this.props.editCommentReply) && !this.state.checkCommentIsReply ? (
                  <div className="comment-details-category-block">
                    {/* Comment View */}
                    {this.state.commentView && (
                      <div className="comment-selected-category-block">
                        <span className="comment-details-category-title">
                          Category
                        </span>
                        <span
                          className={`${MiscFunctionsLocal.checkCategoryValid(clickedComment.category[0]) ? clickedComment.category[0] !== undefined &&
                            clickedComment.category[0].icon : "comment"
                            }BgColor comment-details-selected-category selected-icon-comment`}
                        >
                          <span
                            className={`comment_icon_${MiscFunctionsLocal.checkCategoryValid(clickedComment.category[0]) ? clickedComment.category[0] !== undefined &&
                              clickedComment.category[0].icon : "comment"
                              }_selected`}
                          ></span>
                        </span>
                      </div>
                    )}
                    {/* Comment Edit */}
                    {this.state.commentEdit && (
                      <>
                        <div
                          className="comment-details-category-title-block"
                          onClick={this.handleToggleDropDown}
                        >
                          <span
                            className={`comment-details-category-title ${showDropDown &&
                              `comment-details-category-title-selected`
                              }`}
                          >
                            Category
                          </span>
                          <span
                            className={`${!showDropDown
                              ? `comment_drop_down_arrow`
                              : `comment_drop_down_arrow_selected`
                              }`}
                          ></span>
                        </div>
                        {selectedCategory !== null && (
                          <span
                            className={`comment-details-selected-category selected-icon-${selectedCategory.icon}`}
                          >
                            <span
                              className={`comment_icon_${selectedCategory.icon}_selected`}
                            ></span>
                          </span>
                        )}
                        {showDropDown && (
                          <div className="comment-details-dropdown-category">
                            {newFilterCategories.map((category, i) => {
                              // Check if it's the start of a new row (every 2 items)
                              if (i % 2 === 0) {
                                return (
                                  <div
                                    key={i / 2}
                                    className="comment-details-dropdown-category-row"
                                  >
                                    {/* Render first item in the row */}
                                    <span
                                      className={`comment-details-dropdown-category-block ${category.icon}-icon-block`}
                                      onClick={() =>
                                        this.handleSelectCategory(category)
                                      }
                                    >
                                      <span
                                        className={`comment-details-dropdown-category-icon comment_icon_${category.icon
                                          }  ${selectedCategory &&
                                            selectedCategory.id === category.id
                                            ? `comment_icon_${selectedCategory.icon}_selected selected-icon-${selectedCategory.icon}`
                                            : ""
                                          }`}
                                      ></span>
                                      <span className="comment-details-dropdown-category-text">
                                        {category.label}
                                      </span>
                                    </span>

                                    {/* Render second item in the row if exists */}
                                    {i + 1 < newFilterCategories.length && (
                                      <span
                                        className={`comment-details-dropdown-category-block ${newFilterCategories[i + 1].icon
                                          }-icon-block`}
                                        onClick={() =>
                                          this.handleSelectCategory(
                                            newFilterCategories[i + 1]
                                          )
                                        }
                                      >
                                        <span
                                          className={`comment-details-dropdown-category-icon comment_icon_${newFilterCategories[i + 1].icon
                                            }  ${selectedCategory &&
                                              selectedCategory.id ===
                                              newFilterCategories[i + 1].id
                                              ? `comment_icon_${selectedCategory.icon}_selected selected-icon-${selectedCategory.icon}`
                                              : ""
                                            }`}
                                        ></span>
                                        <span className="comment-details-dropdown-category-text">
                                          {newFilterCategories[i + 1].label}
                                        </span>
                                      </span>
                                    )}
                                  </div>
                                );
                              }
                              // If it's not the start of a new row, return nothing (items are already rendered)
                              return null;
                            })}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ) : null}
              </div>

              <div className="comment-details-user-avatar-block">
                <span className="comment-details-username">
                  {this.state.commentView
                    ? clickedComment.getUserName !== "null null" ? clickedComment.getUserName : this.props.user.display
                    : this.props.commentUpdate
                      ? this.props.commentForUpdate.getUserName
                      : this.props.user.display}
                </span>
                <Avatar
                  avatar={
                    this.state.commentView
                      ? clickedComment.getUserAvatar
                      : this.props.commentUpdate
                        ? this.props.commentForUpdate.getUserAvatar
                        : this.props.user.avatar
                  }
                  name={
                    this.state.commentView
                      ? clickedComment.getUserName
                      : this.props.commentUpdate
                        ? this.props.commentForUpdate.getUserName
                        : this.props.user.display
                  }
                />
              </div>
            </div>
          </div>

          {/* Comment Description */}
          <div className="comment-details-content-block">
            {this.state.commentView && !(this.props.editCommentReply && this.props.commentForReply.id === this.props.clickedComment.id) && (
              <>
                <span className="comment-details-description">
                  {MiscFunctionsLocal.formatCommentText(clickedComment.getContent)[0]
                    .props.children.length <= 150
                    ? MiscFunctionsLocal.formatCommentText(clickedComment.getContent)
                    : this.state.showMore
                      ? MiscFunctionsLocal.formatCommentText(clickedComment.getContent)
                      : MiscFunctionsLocal.formatCommentText(
                        clickedComment.getContent
                      )[0].props.children.substring(0, 150) + "... "}
                  {MiscFunctionsLocal.formatCommentText(clickedComment.getContent)[0]
                    .props.children.length >= 150 ? (
                    <button
                      className="showMoreText"
                      onClick={() => {
                        this.props.changeValue(!this.props.changeCommentHeight)
                        this.setState({ showMore: !this.state.showMore })
                      }}
                    >
                      {this.state.showMore ? "Hide" : "Show more"}
                    </button>
                  ) : null}
                </span>
                {!MiscFunctions.isNull(
                  this.props.clickedComment.recordedAudio
                ) &&
                  !MiscFunctions.isNull(
                    this.props.clickedComment.recordedAudio.audioText
                  ) &&
                  this.props.clickedComment.recordedAudio.audioText !==
                  "This media was deleted and is no longer accessible." && (
                    <span className="comment-details-recorded-audio">
                      <AudioPlayer
                        audioLink={this.props.clickedComment.recordedAudio}
                        comment={this.props.clickedComment}
                      />
                    </span>

                  )}
                {this.state.commentEdit && (
                  <span className="comment-details-recorded-audio">
                    <AudioRecorder
                      handleRecordedAudio={this.handleRecordedAudio}
                    />
                  </span>

                )}
              </>
            )}
            {this.state.commentEdit && (
              <>
                <MentionsInput
                  date-test="input-comment-content"
                  placeholder={this.props.t("messages.add_comment_message")}
                  value={MiscFunctionsLocal.encodeCommentText(this.state.commentContent)}
                  onChange={(ev) => {
                    this.handleCommentContent(ev);
                  }}
                  onFocus={() => this.setState({ contentFocus: true })}
                  onBlur={() => this.setState({ contentFocus: false })}
                  style={mentionsInputStyle_Annotation}
                  a11ySuggestionsListLabel={"Suggested mentions"}
                  className="mention-input"
                >
                  <Mention
                    trigger="@"
                    data={this.state.userMentions}
                    displayTransform={(id, display) => `${display}`}
                    style={{
                      backgroundColor: this.state.contentFocus
                        ? "#F2F2F2"
                        : "#fff",
                    }}
                    markup="@[__display__,__id__]"
                    renderSuggestion={(elem) => {
                      return (
                        <div className="flex flex-row">
                          <div className="suggestion_comment_box_user_image">
                            <span
                              style={{
                                backgroundImage: `url(${elem.avatar})`,
                                width: "25px",
                                height: "25px",
                                display: "block",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                borderRadius: "50%",
                              }}
                            ></span>
                          </div>
                          <div
                            style={{ alignItems: "center", display: "flex" }}
                          >
                            <span>{elem.display}</span>
                          </div>
                        </div>
                      );
                    }}
                    appendSpaceOnAdd={true}
                    className="mention-selected"
                  />
                </MentionsInput>

                <span className="comment-details-record-audio">
                  {!this.state.deleteRecordedAudio &&
                    this.state.commentEdit &&
                    !MiscFunctions.isNull(this.props.commentForUpdate) &&
                    !MiscFunctions.isNull(
                      this.props.commentForUpdate.recordedAudio
                    ) ? (
                    this.props.commentForUpdate.recordedAudio.audioText !==
                      "This media was deleted and is no longer accessible." ? (
                      <span className="comment-details-recorded-audio">
                        <AudioPlayer
                          audioLink={this.props.commentForUpdate.recordedAudio}
                        />
                        <span
                          className="delete_uploaded_file"
                          onClick={() => {
                            this.handleDeleteRecordedAudio(
                              this.props.commentForUpdate.recordedAudio.id,
                              this.props.commentForUpdate.id
                            );
                          }}
                        ></span>
                      </span>
                    ) : (
                      <AudioRecorder
                        handleRecordedAudio={this.handleRecordedAudio}
                      />
                    )
                  ) : (
                    <AudioRecorder
                      handleRecordedAudio={this.handleRecordedAudio}
                    />
                  )}
                </span>
              </>
            )}
            {((this.props.editCommentReply && this.props.commentForReply.id === this.props.clickedComment.id) || this.state.commentReply) && (
              <>
                <MentionsInput
                  placeholder={this.props.t("messages.add_comment_message")}
                  value={MiscFunctionsLocal.encodeCommentText(this.state.commentContent)}
                  onChange={(ev) => {
                    this.handleCommentContent(ev);
                  }}
                  onFocus={() => this.setState({ contentFocus: true })}
                  onBlur={() => this.setState({ contentFocus: false })}
                  style={mentionsInputStyle_Annotation}
                  a11ySuggestionsListLabel={"Suggested mentions"}
                  className="mention-input"
                >
                  <Mention
                    trigger="@"
                    data={this.state.userMentions}
                    displayTransform={(id, display) => `${display}`}
                    style={{
                      backgroundColor: this.state.contentFocus
                        ? "#F2F2F2"
                        : "#fff",
                    }}
                    markup="@[__display__,__id__]"
                    renderSuggestion={(elem) => {
                      return (
                        <div className="flex flex-row">
                          <div className="suggestion_comment_box_user_image">
                            <span
                              style={{
                                backgroundImage: `url(${elem.avatar})`,
                                width: "25px",
                                height: "25px",
                                display: "block",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                borderRadius: "50%",
                              }}
                            ></span>
                          </div>
                          <div
                            style={{ alignItems: "center", display: "flex" }}
                          >
                            <span>{elem.display}</span>
                          </div>
                        </div>
                      );
                    }}
                    appendSpaceOnAdd={true}
                    className="mention-selected"
                  />
                </MentionsInput>

                <span className="comment-details-record-audio">
                  {!this.state.deleteRecordedAudio &&
                    (this.props.editCommentReply && this.props.commentForReply.id === this.props.clickedComment.id) &&
                    !MiscFunctions.isNull(this.props.commentForReply) &&
                    !MiscFunctions.isUndefined(this.props.commentForReply) &&
                    !MiscFunctions.isNull(
                      this.props.commentForReply.recordedAudio
                    ) ? (
                    this.props.commentForReply.recordedAudio.audioText !==
                      "This media was deleted and is no longer accessible." ? (
                      <span className="comment-details-recorded-audio">
                        <AudioPlayer
                          audioLink={this.props.commentForReply.recordedAudio}
                        />
                        <span
                          className="delete_uploaded_file"
                          onClick={() => {
                            this.handleDeleteRecordedAudio(
                              this.props.commentForReply.recordedAudio.id,
                              this.props.commentForReply.id
                            );
                          }}
                        ></span>
                      </span>
                    ) : (
                      <AudioRecorder
                        handleRecordedAudio={this.handleRecordedAudio}
                      />
                    )
                  ) : (
                    <AudioRecorder
                      handleRecordedAudio={this.handleRecordedAudio}
                    />
                  )}
                </span>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(CommentDetials);
