import { createSlice } from '@reduxjs/toolkit';

const videoSlice = createSlice({
    name: 'video',
    initialState: null, // Initial user value
    reducers: {
        setVideo: (state, action) => action.payload, // Update user
        clearVideo: () => null, // Clear user
    },
});

export const { setVideo, clearVideo } = videoSlice.actions;

export default videoSlice.reducer;
