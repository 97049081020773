import { configureStore } from '@reduxjs/toolkit';
import tokenReducer from './tokenSlice';
import userReducer from './userSlice';
import videoReducer from './videoSlice';
import workflowlibraryReducer from './workflowlibrarySlice';

const loggerMiddleware = (store) => (next) => (action) => {
    console.log('Dispatching action:', action);
    return next(action);
};

const store = configureStore({
    reducer: {
        token: tokenReducer, // Top-level token state
        user: userReducer,   // Top-level user state
        video: videoReducer, 
        workflowlibrary: workflowlibraryReducer
        // Add more reducers for other states if needed
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(loggerMiddleware),
});

export default store;
